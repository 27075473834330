import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { ConfigProvider } from "antd"
import "../main.less"

import ru_RU from "antd/es/locale-provider/ru_RU"
import moment from "moment"
import "moment/locale/ru"

moment.locale("ru")

function Meta(props) {
  const { title } = useSiteMetadata()

  return (
    <ConfigProvider locale={ru_RU}>
      <Helmet>
        <title>
          {props.title} | {title}
        </title>

        <meta name="description" content={props.description} />
        <meta name="date" content={props.date} scheme="YYYY-MM-DD" />
        <meta name="theme-color" content="#222" />
        {props.noindex && <meta name="robots" content="noindex"></meta>}
      </Helmet>
      {props.children}
    </ConfigProvider>
  )
}

export default Meta

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  )
  return site.siteMetadata
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noindex: PropTypes.any.isRequired,
  date: function(props, propName, componentName) {
    if (!/\d{4}-\d{2}-\d{2}/.test(props[propName])) {
      return new Error(
        `Prop ${propName} of component ${componentName} have no a valid value`
      )
    }
  },
}
