import React from "react"
import { Link } from "gatsby"
import { Form, Icon, Input, Button, Checkbox } from "antd"
import "./signin.less"

class NormalSigninForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit} className="signin-form">
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [
              { required: true, message: "Ваш e-mail" },
              {
                type: "email",
                message: "Неправильный формат e-mail!",
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="E-mail"
              type="email"
            />
          )}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              {
                pattern: /^.{8,}$/,
                required: true,
                message: "Пароль, указанный при регистрации",
              },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Пароль"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: false,
          })(<Checkbox>Чужой компьютер</Checkbox>)}

          <Link to="/" className="signin-form__forgot">
            Забыли пароль?
          </Link>

          <Button
            type="primary"
            htmlType="submit"
            className="signin-form__button"
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export const SigninForm = Form.create({ name: "signin" })(NormalSigninForm)
