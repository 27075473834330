import React from "react"
import { Link } from "gatsby"
import { Row, Col, Tabs, Icon, Tag } from "antd"
import { useSiteMetadata } from "../components/meta"
import { SigninForm } from "../container/signin/signin"
import { SignupForm } from "../container/signup/signup"
import "./index.less"
import Meta from "../components/meta"

export default () => {
  const { title } = useSiteMetadata()

  return (
    <Meta
      title="SaaS решение для интернет-магазинов"
      date="2019-08-01"
      description=""
      noindex=""
    >
      <div className="container home">
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} sm={24} md={24} lg={13} xl={11} xxl={9}>
            <h1 className="home_h1">{title}</h1>
            <Tag color="#87d068">2019.1-dev1</Tag>
            <p className="home_desc">
              <abbr title="Software as a Service - программный продукт распространяемый по подписке и полностью обслуживаемый разработчиками">
                SaaS
              </abbr>{" "}
              решение для интернет-магазинов
            </p>
            <ul className="home_advantages">
              <li>Серверы (хостинг) в Беларуси</li>
              <li>Регистрация в БелГИЭ</li>
              <li>
                <Link to="/terms">Публичный договор</Link>
              </li>
            </ul>
          </Col>

          <Col xs={24} sm={16} md={12} lg={10} xl={8} xxl={6}>
            <div style={{ width: "100%" }} className="print-hide">
              <Tabs defaultActiveKey="signin">
                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="login" />
                      Личный кабинет
                    </span>
                  }
                  key="signin"
                >
                  <SigninForm />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <span>
                      <Icon type="idcard" />
                      Регистрация
                    </span>
                  }
                  key="signup"
                >
                  <SignupForm />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    </Meta>
  )
}
